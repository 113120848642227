import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainPageRoutingModule } from './main-page-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';

const PRIME_MODULES = [
  ToastModule,
  DropdownModule,
  AvatarGroupModule,
  AvatarModule,
  BadgeModule,
  MenuModule,
]


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MainPageRoutingModule,
    LayoutModule,
    ...PRIME_MODULES
  ]
})
export class MainPageModule { }
