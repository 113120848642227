import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  // All APIs Calling Method

  // Get Method
  public httpGet(endpoint: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${endpoint}`);
  }

  // Get Method
  public httpGetWithParams(endpoint: string, params: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}${endpoint}?${params}`);
  }

  // Post Method
  public httpPost(endpoint: string, data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}${endpoint}`, data);
  }

  // Put Method
  public httpPut(endpoint: string, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}${endpoint}`, data);
  }

  // Delete Method
  public httpDelete(endpoint: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${endpoint}`);
  }

  /** Fork Join */
  public forkJoin(urls: string[]): Observable<any> {
    let response: any = [];
    for (let i = 0; i < urls.length; i++) {
      response.push(this.httpGet(urls[i]));
    }
    return forkJoin(response);
  }
}
