import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class SpinnerService {
    private spinnerVisible = new BehaviorSubject<boolean>(false);
    spinnerVisible$ = this.spinnerVisible.asObservable();

    /** Show Spinner */
    showSpinner(): void {
        this.spinnerVisible.next(true);
    }

    /** Hide Spinner */
    hideSpinner(): void {
        this.spinnerVisible.next(false);
    }
}