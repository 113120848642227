import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-skeleton',
  standalone: true,
  imports: [CommonModule, SkeletonModule],
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {
  @Input() shape?: any;
  @Input() style?: any;
  @Input() borderRadius?: any;
  @Input() size?: any;
  @Input() width?: any;
  @Input() height?: any;
  @Input() styleClass?: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
