import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { yyyymmdd } from 'src/app/util/date';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { IState } from 'src/app/core/interface/state.interface';
import { form } from 'src/app/util/constant/form.config';
import { ApiService } from 'src/app/shared/services/http/api.service';
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { DateFormatDirective } from '../directive/date-format.directive';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { CommancssClassesType, commancssClasses } from 'src/app/util/constant/cssClass.config';
import { NotesService } from 'src/app/portal/notes/services/notes.service';

@Component({
	selector: 'app-form-popup',
	templateUrl: './form-popup.component.html',
	styleUrls: ['./form-popup.component.scss'],
	standalone: true,
	imports: [CommonModule, AutoCompleteModule, MenuModule, OverlayPanelModule, InputMaskModule, DropdownModule, CalendarModule, InputTextModule, FormsModule, ReactiveFormsModule, DynamicDialogModule, InputNumberModule, DateFormatDirective, MultiSelectModule],
	providers: [DialogService, NotesService]
})

export class FormPopupComponent implements OnInit {
	@ViewChild('autoComplete') autoComplete!: AutoComplete;
	@ViewChild('autoCompletegender') autoCompletegender!: AutoComplete;
	@ViewChild('multiSelect') multiSelect!: MultiSelect;
	commanCss: CommancssClassesType = commancssClasses;
	form!: FormGroup;
	states: IState[] = [];
	isSubmitting: boolean = false;
	labelClass: string = form.primary.labelClass;
	inputClass: string = form.primary.inputClass;
	errorTextClass: string = form.primary.errorTextClass;
	country: MenuItem[] | any;
	selectedCountry: any = 'usa';
	inputMask: any = '(999) 999-9999';
	selectedDrivers!: any[];

	constructor(private apiService: ApiService, private readonly spinner: SpinnerService, private readonly dialogRef: DynamicDialogRef, public readonly config: DynamicDialogConfig, private toastr: ToastrService, private nt: NotesService) { }

	ngOnInit(): void {
		this.country = [
			{
				label: 'Select Country',
				items: [
					{
						label: 'United States  +1',
						command: () => this.setCountry('usa'),
					},
					{
						label: 'India  +91',
						command: () => this.setCountry('ind'),
					},
				]
			}
		]
		this.initForm();
	}

	// Set the selected country for mobile number input
	setCountry(countryCode: string) {
		this.selectedCountry = countryCode;
		this.inputMask = countryCode === 'usa' ? '(999) 999-9999' : '9999999999';
		this.form.get('mobile')?.setValidators(this.getMobileNumberValidators(countryCode));
	}

	// Get mobile number validators based on the selected country
	getMobileNumberValidators(countryCode: string) {
		if (countryCode === 'usa') {
			return [Validators.required, Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/)];
		} else if (countryCode === 'ind') {
			return [Validators.required, Validators.pattern(/^(?:\+91|0)?[6789]\d{9}$/)];
		}
		return null;
	}

	/** NgOnDestroy */
	ngOnDestroy(): void {
		this.form.reset();
	}

	get f() {
		return this.form.controls;
	}

	private initForm(): void {
		this.form = this.config.data.form;
		if (this.config.data.mode === 'update') {
			// For mobile number update
			const mobileFormControl = this.form.get('mobile');
			const dialCodeRegex = /^(?:\+1|\+91)/;
			const match = dialCodeRegex.exec(mobileFormControl?.value);
			if (match) {
				const dial_code = match[0];
				if (dial_code == '+1') {
					this.selectedCountry = 'usa';
					this.inputMask = '(999) 999-9999';
				} else {
					this.selectedCountry = 'ind';
					this.inputMask = '9999999999';
				}
				const mobile = mobileFormControl?.value?.substring(dial_code.length);
				mobileFormControl?.patchValue(mobile);
			}
			// For state update
			const stateControle: any = this.form.get("state_id");
			const foundStateObject = this.config.data.formActions.initailData.states.find((obj: any) => obj.id == stateControle?.value);
			stateControle?.patchValue(foundStateObject);
			// For state update
			const genderControl: any = this.form.get("gender");
			const foundGender = this.config.data.formActions.initailData.gender.find((obj: any) => obj.id == genderControl?.value);
			genderControl?.patchValue(foundGender);
			// For Expiration date
			const expiration_date: any = this.form.get("expiration_date");
			expiration_date?.patchValue(new Date(expiration_date.value));
			//For Date of birth 
			const date_of_birth: any = this.form.get("date_of_birth");
			date_of_birth?.patchValue(new Date(date_of_birth.value));
		}
	}

	// Submit address form
	submit(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.spinner.showSpinner();
		this.isSubmitting = false;
		var mobile: any = '';
		for (let i = 0; i < this.config.data.formHTML.length; i++) {
			const el: any = this.config.data.formHTML[i];
			if (el?.dateFormatValue == 'yyyy-mm-dd') {
				this.form.get(el.formControlName)?.setValue(yyyymmdd(this.form.get(el.formControlName)?.value))
			}
			if (el?.formatMobile) {
				if (this.selectedCountry === 'usa') {
					const phoneNumber = this.form.get(el.formControlName)?.value;
					const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
					mobile = '+1' + formattedPhoneNumber;
				} else if (this.selectedCountry === 'ind') {
					mobile = '+91' + this.form.get(el.formControlName)?.value;
				}
				this.form.get(el.formControlName)?.setValue(mobile);
			}
		}
		let method;
		const payload: any = { ...this.form.value, ...this.config.data.userType }
		if (payload.state_id) {
			payload.state_id = payload.state_id?.id;
		}
		if (payload.gender) {
			payload.gender = payload.gender?.id;
		}
		if (this.config?.data?.omitEmptyValues) {
			Object.keys(payload).forEach((item: any) => {
				if (!item) delete payload[item]
			});
		}
		console.log(payload);
		if (this.config.data.mode == 'create') {
			method = this.apiService.httpPost(this.config.data.submitUrl, payload);
		} else {
			method = this.apiService.httpPut(this.config.data.submitUrl, payload);
		}
		method.subscribe({
			next: (res: any) => {
				if (res.status == 200) {
					this.spinner.hideSpinner();
					if (this.config.data.closeOnComplete) {
						this.dialogRef.close(res?.data ? res?.data : true);
					}
				}
			},
			error: () => this.spinner.hideSpinner()
		});
	}

	cancel(): void {
		this.dialogRef.close();
	}

	/** On focus */
	onshow(event: any, type: string) {
		if (type == 'state') {
			this.autoComplete.show();
			this.config.data.formActions.initailData.states = [...this.config.data.formActions.initailData.states];
		} else {
			this.autoCompletegender.show();
			this.config.data.formActions.initailData.gender = [...this.config.data.formActions.initailData.gender];

		}
	}

	/** Filter Item */
	filterItems(event: AutoCompleteCompleteEvent, type: string) {
		if (type == 'state') {
			this.autoComplete.show();
			this.config.data.formActions.initailData.states = [...this.config.data.formActions.initailData.states];
		} else {
			this.autoCompletegender.show();
			this.config.data.formActions.initailData.gender = [...this.config.data.formActions.initailData.gender];

		}
	}

	/** Filter Item For Input Search */
	async inputFilterItems(event: any, type: string) {
		switch (type) {
			// Enrollee
			case 'tenant':
				const res: any[] = await this.nt.getInitialDataEnrollee(event.filter);
				if (res.length) {
					this.config.data.formActions.initailData.tenant = [...this.config.data.formActions.initailData.tenant];
				}
				break;
			case 'driver':
				const res1: any[] = await this.nt.getInitialDataDriver(event.filter);
				if (res1.length) {
					this.config.data.formActions.initailData.tenant = [...this.config.data.formActions.initailData.tenant];
				}
				break;
			case 'trip':
				const res2: any[] = await this.nt.getInitialTrip(event.filter);
				if (res2.length) {
					this.config.data.formActions.initailData.tenant = [...this.config.data.formActions.initailData.tenant];
				}
				break;
		}
	}

	/** Dismiss multi select */
	dismissMultiSelect() {
		this.multiSelect.hide();
	}

	/** Done multi select */
	doneMultiSelect() {
		this.multiSelect.hide();
	}
}