
export interface IMenu {
    label: string,
    route: string,
    userType: number,
}

/** Enrollee Header Menus */
export const PORTAL_MENU_ENROLLEE: IMenu[] = [
    { label: "Home", route: 'dashboard', userType: 0 },
    { label: "Profile", route: 'profile', userType: 0 },
    { label: "Trips", route: 'trips', userType: 2 },
    { label: "Service", route: 'services', userType: 2 },
    { label: "Notes", route: 'notes', userType: 2 },
    { label: "Notifications", route: 'notifications', userType: 2 },

];

/** Medical Provider Menus */
export const PORTAL_MENU_MP: IMenu[] = [
    { label: "Home", route: 'dashboard', userType: 0 },
    { label: "Profile", route: 'profile', userType: 0 },
    { label: "Trips", route: 'trips', userType: 3 },
    { label: "Users", route: 'users', userType: 3 },
    { label: "Reports", route: 'reports', userType: 3 },
    { label: "Notes", route: 'notes', userType: 3 },
    { label: "Notifications", route: 'notifications', userType: 3 },

];

/** Service Provider Menus */
export const PORTAL_MENU_SP: IMenu[] = [
    { label: "Home", route: 'dashboard', userType: 0 },
    { label: "Profile", route: 'profile', userType: 0 },
    { label: "Requests", route: 'requests', userType: 4 },
    { label: "Users", route: 'users', userType: 4 },
    { label: "Notes", route: 'notes', userType: 4 },
    { label: "Service", route: 'service', userType: 4 },
    { label: "Settings", route: 'settings', userType: 4 },
    { label: "Reports", route: 'reports', userType: 4 },
    { label: "Notifications", route: 'notifications', userType: 4 },
];