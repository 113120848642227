export enum UserTypeEnum {
    TENANT = 'tenant',
    MEDICAL_PROVIDER = 'medical-provider',
    SERVICE_PROVIDER = 'service-provider',
    CONTRACTOR = 'contractor',
}

export enum UserStatusEnum {
    UNVERIFIED = '1',
    VERIFIED = '2',
    ACTIVATED = '3',
    DEACTIVATED = '4',
    DELETED = '5',
    BLOCK = '6',
}

export enum UserTypeIdEnum {
    ADMIN = '1',
    TENANT = '2',
    MANAGEMENT = '3',
    MEDICAL_PROVIDER = '3',
    SERVICE_PROVIDER = '4',
    CONTRACTOR = '5',
    VEHICLE = '6',
}

export enum ContractorSubype {
    Contractor = '5',
    Worker = '11',
    Delivery = '12',
    Driver = '13',
    HVAC = '14',
    Repairmen = '15',
    Doorman = '16',
    Frontdesk = '17',
    PetCare = '18',
    Cleaner = '19',
    HomeAide = '20',
}

export enum UserTypeIdForAuth {
    TENANT = '2',
    MEDICAL_PROVIDER = '3',
    SERVICE_PROVIDER = '4',
}

export enum UserAddressType {
    PHYSICAL = 'physical',
    MAILING = 'mailing',
}

export const GenderEnum: any = [
    { id: 'M', gender: 'Male' },
    { id: 'F', gender: 'Female' },
    { id: 'O', gender: 'Other' },
];


export const ContractorTypes = [
    { id: ContractorSubype.Contractor, type: 'Contractor' },
    { id: ContractorSubype.Worker, type: 'Worker' },
    { id: ContractorSubype.Delivery, type: 'Delivery' },
    { id: ContractorSubype.Driver, type: 'Driver' },
    { id: ContractorSubype.HVAC, type: 'HVAC' },
    { id: ContractorSubype.Repairmen, type: 'Repairmen' },
    { id: ContractorSubype.Doorman, type: 'Doorman' },
    { id: ContractorSubype.Frontdesk, type: 'Frontdesk' },
    { id: ContractorSubype.PetCare, type: 'PetCare' },
    { id: ContractorSubype.Cleaner, type: 'Cleaner' },
    { id: ContractorSubype.HomeAide, type: 'HomeAide' }
];


export const UserTypes = [
    { id: UserTypeIdEnum.ADMIN, type: 'Admin' },
    { id: UserTypeIdEnum.TENANT, type: 'Tenant' },
    { id: UserTypeIdEnum.MEDICAL_PROVIDER, type: 'Medical Provider' },
    { id: UserTypeIdEnum.SERVICE_PROVIDER, type: 'Service Provider' },
    { id: UserTypeIdEnum.CONTRACTOR, type: 'Contractor' },
];