<div class="timer mr-2">
    <ng-container *ngIf="view == 'desktop'">
        {{ today | date:'MMM dd, yyyy' + ', ' + clock }} {{ timeZoneCode }}
    </ng-container>
    <ng-container *ngIf="view == 'mobile'">
        <div>
            {{ today | date:'MMM dd, yyyy' }}
        </div>
        <div>
            {{ clock }} {{ timeZoneCode }}
        </div>
    </ng-container>
</div>