import { IFormHtml } from "src/app/shared/interfaces/form.interface";

export const physicalAddressForm: IFormHtml[] = [
	{
		formControlName: "address",
		label: "Street",
		type: "googlePlaceAutoComplate",
		requiredMsg: "*Street is required",
		placeholder: "Enter Street"
	},
	{
		formControlName: "address2",
		label: "Street2",
		type: "text",
		requiredMsg: "*Street2 is required",
		placeholder: "Enter Street2"
	},
	{
		formControlName: "apartment",
		label: "Apartment #",
		type: "text",
		requiredMsg: "*Apartment # is required",
		placeholder: "Enter Apartment #"
	},
	{
		formControlName: "city",
		label: "City",
		type: "text",
		requiredMsg: "*City is required",
		placeholder: "Enter City"
	},
	{
		formControlName: "state_id",
		type: "dropdown",
		label: "State",
		requiredMsg: "*State is required",
		placeholder: "Select State",
		optionLabel: "state_name",
		optionValue: "state_name",
		optionListKey: 'states',
	},
	{
		formControlName: "zipcode",
		label: "Zipcode",
		type: "text",
		requiredMsg: "Zipcode is required",
		placeholder: "Enter Zipcode",
		patternMsg: "Invalid Zipcode",
		maxLength: 6,
	},
	{
		formControlName: "floor",
		label: "Floor",
		type: "text",
		requiredMsg: "*Floor is required",
		placeholder: "Enter Floor"
	},
	{
		formControlName: "elevator",
		label: "Elevator",
		type: "radio",
		requiredMsg: "*Elevator is required",
		optionListKey: 'elevator',
	},
	{
		formControlName: "pickup_point",
		label: "Pickup Point",
		type: "dropdown",
		requiredMsg: "*Pickup Point is required",
		placeholder: "Select Pickup Point",
		optionListKey: 'pickup_point',
		optionLabel: 'label',
		optionValue: 'value',
	},
]

export const mailingAddressForm: IFormHtml[] = [
	{
		formControlName: "address",
		label: "Street",
		type: "text",
		requiredMsg: "*Street is required",
		placeholder: "Enter Street"
	},
	{
		formControlName: "address2",
		label: "Street2",
		type: "text",
		requiredMsg: "*Street2 is required",
		placeholder: "Enter Street2"
	},
	{
		formControlName: "city",
		label: "City",
		type: "text",
		requiredMsg: "*City is required",
		placeholder: "Enter City"
	},
	{
		formControlName: "state_id",
		type: "dropdown",
		label: "State",
		requiredMsg: "*State is required",
		placeholder: "Select State",
		optionLabel: "state_name",
		optionValue: "id",
	},
	{
		formControlName: "zipcode",
		label: "Zipcode",
		type: "text",
		requiredMsg: "Zipcode is required",
		placeholder: "Enter Zipcode",
		patternMsg: "Invalid Zipcode",
		maxLength: 6,
	},
]