import { Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService } from "primeng/dynamicdialog";
import { UserTypeIdEnum } from "src/app/shared/interfaces/user.interface";
import { ApiService } from "src/app/shared/services/http/api.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { ToastrService } from "src/app/shared/services/toastr.service";
import { limit } from "src/app/util/constant";
import { formActions } from "../config/notes.config";

@Injectable()

export class NotesService {
    UserTypeIdEnum!: UserTypeIdEnum;
    pagePayload: any = {
        page: 1,
        limit: limit,
        user_type: 0,
    };

    /** Notes Form for the Enrollee Driver and Trip */
    notesForm: FormGroup = this.fb.group({
        title: new FormControl("", [Validators.required]),
        type_id: new FormControl("", [Validators.required]),
        priority: new FormControl("", [Validators.required]),
        description: new FormControl("", [Validators.required]),
        type: new FormControl("", [Validators.required])
    });

    constructor(private readonly spinner: SpinnerService, private fb: FormBuilder, private apiService: ApiService, private sharedService: SharedService, private toastr: ToastrService, private dialogService: DialogService) {
    }


    // For Enrollee
    getInitialDataEnrollee(searchType: any): Promise<any[]> {
        this.pagePayload.user_name = searchType;
        this.pagePayload.user_type = UserTypeIdEnum.TENANT;
        this.pagePayload.user_sub_type = null;
        this.pagePayload.pra_trip_id = null
        const urls: any = this.sharedService.formUrlParam('user/list', this.pagePayload);
        return new Promise((resolve, reject) => {
            this.apiService.httpGet(urls).subscribe({
                next: (res: any) => {
                    if (res.status == 200 && res.success) {
                        res.data = res.data.map((i: any) => {
                            return { ...i, displayName: i.first_name + ' ' + i.last_name };
                        });
                        formActions.initailData.tenant = res.data;
                        formActions.initailData.isInitialDataLoaded = true;
                        resolve(res);
                    }
                }, error: (err: any) => {
                    console.log(err);
                    reject(false);
                }, complete: () => { }
            });
        });
    }

    // For Driver 
    getInitialDataDriver(searchType: any): Promise<any[]> {
        this.pagePayload.user_name = searchType;
        this.pagePayload.user_type = UserTypeIdEnum.DRIVER;
        this.pagePayload.user_sub_type = [11];
        this.pagePayload.pra_trip_id = null
        const urls: any = this.sharedService.formUrlParam('user/list', this.pagePayload);
        return new Promise((resolve, reject) => {
            this.apiService.httpGet(urls).subscribe({
                next: (res: any) => {
                    if (res.status == 200 && res.success) {
                        res.data = res.data.map((i: any) => {
                            return { ...i, displayName: i.first_name + ' ' + i.last_name };
                        });
                        formActions.initailData.driver = res.data;
                        formActions.initailData.isInitialDataLoaded = true;
                        resolve(res);
                    }
                }, error: (err: any) => {
                    console.log(err);
                    reject(false);
                }, complete: () => { }
            });
        });
    }


    // For Driver 
    getInitialTrip(searchType: any): Promise<any[]> {
        this.pagePayload.enrollee_search = searchType;
        this.pagePayload.user_sub_type = null;
        this.pagePayload.user_type = null;
        this.pagePayload.user_name = null;
        const urls: any = this.sharedService.formUrlParam('trip/fetch', this.pagePayload);
        return new Promise((resolve, reject) => {
            this.apiService.httpGet(urls).subscribe({
                next: (res: any) => {
                    if (res.status == 200 && res.success) {
                        console.log("res>>>>>>>", res)
                        res.data = res.data.map((i: any) => {
                            return { ...i, displayName: i.pra_trip_id + ' - ' + (i.enrollee_detail?.first_name ? i.enrollee_detail?.first_name : 'N/') + ' ' + (i.enrollee_detail?.last_name ? i.enrollee_detail?.last_name : 'A') };
                        });
                        formActions.initailData.trip = res.data;
                        formActions.initailData.isInitialDataLoaded = true;
                        resolve(res);
                    }
                }, error: (err: any) => {
                    console.log(err);
                    reject(false);
                }, complete: () => { }
            });
        });
    }

    async actionDecider(event: string, user: any) {
        let response: any;
        if (event == 'delete') {

        } else if (event == 'edit') {

        }
        return response;
    }
}