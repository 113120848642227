import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReusableFooterComponent } from './components/reusable-footer/reusable-footer.component';
import { ApiService } from './services/http/api.service';
import { ReusableDialogComponent } from './components/reusable-dialog/reusable-dialog.component';

/****************** PRIMENG MODULES  *******************/
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

const PRIME_MODULES = [
  DialogModule,
  InputTextModule,
  ButtonModule,
]

@NgModule({
  declarations: [
    ReusableFooterComponent,
    ReusableDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ...PRIME_MODULES
  ],
  exports: [
    ReusableFooterComponent,
    ReusableDialogComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [ApiService, MessageService]
})
export class SharedModule { }
