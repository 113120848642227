// common-css-classes.ts
export type CommancssClassesType = typeof commancssClasses;

export const commancssClasses: any = {
    detailsLabel: 'w-1/2 text-[14px] text-[#99A6B7] font-medium',
    detailsValue: 'w-1/2 text-[13px] text-[#334D6E] font-normal',
    dialogCancelBtn: 'bg-[#F2F0EA] text-[13px] text-[#334D6E] font-medium px-6 py-2 rounded-lg',
    dialogSubmitBtn: 'bg-[#334D6E] text-[13px] text-[#FFFFFF] font-medium px-6 py-2 rounded-lg',
    // Dashboard
    cardHeaderTitle: 'w-full px-4 py-2 text-[#334D6E] text-[15px] font-medium',
    cardMuteLabel: 'w-full px-4 py-2 text-[#707683] text-[13px] font-normal cursor-pointer',
    cardLabel: 'w-full px-4 py-2 text-[#2C71C3] text-[13px] font-normal hover:text-[#707683] cursor-pointer',
    //Signup Page
    signupFormLabel: 'block mb-2 text-[13px] text-gray-900 dark:text-white'
}