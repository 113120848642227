<div class="sub-header" [ngClass]="{ 'shadow-active': isScrollActive }">
  <ul
    class="flex flex-wrap text-sm font-medium text-center border-b overflow-auto rounded-t-lg justify-between items-center"
  >
    <li class="mr-2 pl-5 max-[838px]:p-4">
      <ul class="navbar flex flex-row p-0">
        <li class="nav-item mx-2 relative" *ngFor="let item of menuItems">
          <a
            *ngIf="
              (item.userType == 0 ||
                userStatusEnum.ACTIVATED == userDetail.status) &&
              item.label !== 'Preferred'
            "
            class="px-3 py-1 no-underline cursor-pointer"
            [routerLink]="item.route"
            [routerLinkActive]="['active']"
          >
            {{ item.label }}
          </a>
          <div *ngIf="item.label === 'Preferred'" class="relative">
            <a
              (click)="op.toggle($event)"
              class="px-2 py-0 no-underline cursor-pointer flex items-center gap-1"
                            [ngClass]="{'active': isPreferredActive}"

            >
              {{ item.label }}
              <i class="pi pi-chevron-down text-xs"></i>
            </a>

            <p-overlayPanel #op styleClass="overlay-nav">
              <ul class="p-0 m-0 list-none" (click)="op.hide()">
                <li
                  *ngFor="let option of preferredOptions"
                  (click)="handlePreferredOption(option.id)"
                  class="pointer text-sm px-5 py-2 color-primary hover:bg-gray-100"
                  [ngClass]="{'active': isPreferredOptionActive(option.id)}"
                >
                  {{ option.label }}
                </li>
              </ul>
            </p-overlayPanel>
          </div>
        </li>
      </ul>
    </li>
    <li>
      <app-clock
        class="hidden md:block p-4 sm:ml-4 text-[15px] font-normal text-[#2C71C3]"
        view="desktop"
      >
      </app-clock>
    </li>
  </ul>
</div>
