<div class="card  justify-content-center service-reusable-dialog">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <p class="mt-5 text-[13px] text-[#334D6E]">
            {{message}}
        </p>
        <div class="input-otp mt-5 mb-2">
            <input type="text" pInputText maxlength="4" formControlName="otp" placeholder="0 0 0 0" />
        </div>
        <div *ngIf="form.get('otp').invalid && form.get('otp').touched" class="text-red-500	text-xs mt-1">
            *OTP Number is required.
        </div>
        <a class="text-[13px] font-normal text-gray-900 dark:text-white align-super cursor-pointer">
            <span class="cursor-not-allowed" [ngClass]="{'resend-button' : countdown == 0}" (click)="resendOtp()">
                Resend
            </span>
            OTP in 00.{{ countdown | number: '2.0-0' }}
        </a>
        <div class="text-center processbutton mt-8">
            <button type="button" (click)="cancel()" class="{{commanCss?.dialogCancelBtn}}">
                Cancel
            </button>
            <button type="submit" class="bg-[#334D6E] text-[13px] ml-3 text-[#FFFFFF] font-medium px-6 py-2 rounded-lg">
                Submit
            </button>
        </div>
    </form>
</div>