import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { getTimeZoneTimeObj, getTimezoneName } from 'src/app/util/clock';

@Component({
	selector: 'app-clock',
	templateUrl: './clock.component.html',
	styleUrls: ['./clock.component.scss'],
	standalone: true,
	imports: [CommonModule],
})

export class ClockComponent implements OnInit {
	@Input() view!: string;
	today: Date = new Date();
	clock!: string;
	timeZoneCode: string = getTimezoneName();
	private timeZoneOffsetminutes: number = this.today.getTimezoneOffset();
	private clockTimeout: any;

	constructor(private readonly cd: ChangeDetectorRef) { }

	ngOnInit(): void {
		this.displayTime();
	}

	/** Display Time */
	private displayTime(): void {
		const requiredDate = getTimeZoneTimeObj(this.timeZoneOffsetminutes)
		let hours: any = requiredDate.h;
		let minutes: any = requiredDate.m;
		let seconds: any = requiredDate.s;
		if (hours > 24) hours = hours - 24;
		// if (hours == 0) hours = 24;
		if (hours <= 9) hours = "0" + hours;
		if (minutes <= 9) minutes = "0" + minutes;
		if (seconds <= 9) seconds = "0" + seconds;
		this.clock = hours + ":" + minutes + ":" + seconds;
		this.cd.markForCheck();
		this.clockTimeout = setTimeout(() => {
			this.displayTime()
		}, 1000);
	}

	/** NgOnDestroy */
	ngOnDestroy(): void {
		clearTimeout(this.clockTimeout);
	}
}
