import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService, private readonly spinner: SpinnerService, public sharedService: SharedService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authRequest: any = request;
    // Add authorization token to the request headers;
    const authToken = localStorage.getItem('PRAtoken');
    authRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });
    // Handle the request and catch any errors
    return next.handle(authRequest).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (request.method == 'DELETE' && event.body.status === 200) {
          this.toastr.showSuccess("Record deleted successfully!");
        } else if (request.method == 'PUT' && event.body.status === 200) {
          this.toastr.showSuccess(event.body.message || "Record updated successfully!");
        } else if (request.method == 'POST' && event.body.status === 200) {
          this.toastr.showSuccess(event.body.message || "Record created successfully!");
        }
      }
    }),
      catchError((error: HttpErrorResponse) => {
        this.errorHandler(error);
        this.spinner.hideSpinner();
        return throwError(error);
      })
    );
  }

  /** Error handling */
  private errorHandler(error: HttpErrorResponse, type: string = 'error') {
    console.log(error)
    switch (error.status) {
      case 401: {
        this.toastr.showError(error.error.message);
        this.sharedService.logout();
        break;
      }
      case 400: {
        this.toastr.showError(error.error.message);
        break;
      }
      case 404: {
        this.toastr.showError(error.error.message);
        break;
      }
      case 500: {
        this.toastr.showError(error.error.message);
        break;
      }
      case 403: {
        this.toastr.showError(error.error.message);
        break;
      }
      case 422: {
        this.toastr.showError(error.error.errors[0].msg);
        break;
      }
      case 0: {
        this.toastr.showError('Seems there is some problem with the server. Try later!');
        break;
      }
    }
  }
}


