import { IListHeaderConfig } from 'src/app/core/interface/list-header.interface';
import { IFormHtml } from 'src/app/shared/interfaces/form.interface';
import {
    ContractorSubype,
    ContractorTypes,
    GenderEnum,
    UserTypeIdEnum,
} from 'src/app/shared/interfaces/user.interface';

export const userHeaderConfig: IListHeaderConfig = {
    title: 'Manage Users',
    search: {
        isSearch: true,
        paceholder: 'Search',
    },
    export: {
        isExport: true,
        text: 'Export CSV',
        type: 0,
        subBtns: [],
    },
    add: {
        isAdd: true,
        btnText: '+  Add Account',
        type: 1,
        subBtns: [
            { label: 'Customer', user_type: 2, user_sub_type: 6 },
            { label: 'Contractor', user_type: 5, user_sub_type: 11 },
            { label: 'Vehicle', user_type: null, user_sub_type: null },
        ],
    },
    columns: {
        colLeftClass: 'md:col-3',
        colRightClass: 'md:col-9',
    },
};

const userSubTabs: IUserTabs[] = [
    {
        title: 'My Contractors',
        slug: 'contractors',
        user_sub_type: UserTypeIdEnum.CONTRACTOR,
        disabled: false,
    },
    {
        title: 'All',
        slug: 'all',
        user_sub_type: UserTypeIdEnum.TENANT,
        disabled: false,
    },
    {
        title: 'New',
        slug: 'new',
        user_sub_type: UserTypeIdEnum.TENANT,
        disabled: false,
    },
    {
        title: 'Verified',
        slug: 'verified',
        user_sub_type: UserTypeIdEnum.TENANT,
        disabled: false,
    },
    {
        title: 'Activated',
        slug: 'activated',
        user_sub_type: UserTypeIdEnum.TENANT,
        disabled: false,
    },
    {
        title: 'Deactivated',
        slug: 'deactivated',
        user_sub_type: UserTypeIdEnum.TENANT,
        disabled: false,
    },
    {
        title: 'Deleted',
        slug: 'deleted',
        user_sub_type: UserTypeIdEnum.TENANT,
        disabled: false,
    },
    {
        title: 'Blocked',
        slug: 'blocked',
        user_sub_type: UserTypeIdEnum.CONTRACTOR,
        disabled: false,
    },

    {
        title: 'Explore',
        slug: 'explore',
        user_sub_type: UserTypeIdEnum.CONTRACTOR,
        disabled: false,
    },
];

export const userTabs: IUserTabs[] = [
    {
        title: 'Customer',
        slug: 'tenant',
        user_sub_type: UserTypeIdEnum.TENANT,
        disabled: false,
        children: userSubTabs,
    },
    {
        title: 'Contractor',
        slug: 'contractor',
        user_sub_type: UserTypeIdEnum.CONTRACTOR,
        disabled: false,
        children: userSubTabs.filter((tab) =>
            ['contractors', 'new', 'verified', 'activated', 'deactivated', 'explore'].includes(
                tab.slug
            )
        ),
    },
    {
        title: 'Vehicle',
        slug: 'vehicle',
        user_sub_type: UserTypeIdEnum.TENANT,
        disabled: false,
        children: userSubTabs.filter((tab) => tab.title !== 'Blocked' && tab.title !== 'My Contractors' && tab.title !== 'Explore'),
    },
];

export interface IUserTabs {
    title: string;
    slug: string;
    user_sub_type: string;
    children?: any[];
    disabled: boolean;
}

export const driverFormHtml: IFormHtml[] = [
    {
        formControlName: 'first_name',
        label: 'First Name',
        type: 'text',
        requiredMsg: '*First Name is required',
        placeholder: 'Enter First Name',
    },
    {
        formControlName: 'last_name',
        type: 'text',
        label: 'Last Name',
        requiredMsg: '*Last name is required',
        placeholder: 'Enter Last Name',
    },
    {
        formControlName: 'email',
        type: 'email',
        label: 'Email',
        requiredMsg: '*Email is required',
        patternMsg: 'Invalid Email',
        placeholder: 'Enter Email',
    },
    {
        formControlName: 'mobile',
        type: 'inputMask',
        label: 'Phone',
        requiredMsg: '*Phone no. is required',
        placeholder: 'Enter mobile number',
        formatMobile: true,
    },
    {
        formControlName: 'date_of_birth',
        label: 'Date of Birth',
        type: 'calendar',
        requiredMsg: '*Date of Birth is required.',
        placeholder: 'Enter Date of Birth.',
    },
    {
        formControlName: 'vehicle_plate',
        type: 'text',
        label: 'Vehicle Plate',
        requiredMsg: '*Vehicle plate is required',
        placeholder: 'Enter vehicle plate number',
    },
    {
        formControlName: 'driver_licence',
        type: 'text',
        label: 'Driver License',
        requiredMsg: '*Driver License is required',
        placeholder: 'Enter Driver License number',
    },
    {
        formControlName: 'tlc_number',
        type: 'text',
        label: 'TLC License',
        requiredMsg: '*TLC License is required',
        placeholder: 'Enter TLC License number',
    },
    {
        formControlName: 'state_id',
        type: 'dropdown',
        label: 'State',
        requiredMsg: '*State is required',
        placeholder: 'Select State',
        optionLabel: 'state_name',
        optionValue: 'id',
    },
    {
        formControlName: 'zipcode',
        label: 'Zipcode',
        type: 'text',
        requiredMsg: 'Zipcode is required',
        patternMsg: 'Invalid Zipcode',
        placeholder: 'Enter Zipcode',
        maxLength: 6,
    },
    {
        formControlName: 'gender',
        type: 'dropdown',
        label: 'Gender',
        requiredMsg: '*Gender is required',
        placeholder: 'Select Gender',
        optionLabel: 'gender',
        optionValue: 'id',
    },
];

export const tenantFormHtml: IFormHtml[] = [
  {
      formControlName: "first_name",
      label: "First Name",
      type: "text",
      requiredMsg: "*First Name is required",
      placeholder: "Enter First Name"
  },
  {
      formControlName: "last_name",
      type: "text",
      label: "Last Name",
      requiredMsg: "*Last name is required",
      placeholder: "Enter Last Name"
  },
  {
      formControlName: "email",
      type: "email",
      label: "Email",
      requiredMsg: "*Email is required",
      patternMsg: "Invalid Email",
      placeholder: "Enter Email"
  },
  {
      formControlName: "mobile",
      type: "inputMask",
      label: "Phone",
      requiredMsg: "*Phone no. is required",
      placeholder: "Enter mobile number",
      formatMobile: true
  },
  {
      formControlName: "state_id",
      type: "dropdown",
      label: "State",
      requiredMsg: "*State is required",
      placeholder: "Select State",
      optionLabel: "state_name",
      optionValue: "id",
  },
  {
      formControlName: "zipcode",
      label: "Zipcode",
      type: "text",
      requiredMsg: "Zipcode is required",
      patternMsg: "Invalid Zipcode",
      placeholder: "Enter Zipcode",
      maxLength: 6,
  }
];

export const contractorHtmlForm: IFormHtml[] = [
    {
        formControlName: 'first_name',
        label: 'First Name',
        type: 'text',
        requiredMsg: '*First Name is required',
        placeholder: 'Enter First Name',
    },
    {
        formControlName: 'mobile',
        type: 'inputMask',
        label: 'Phone',
        requiredMsg: '*Phone no. is required',
        placeholder: 'Enter mobile number',
        formatMobile: true,
    },
    {
        formControlName: 'last_name',
        type: 'text',
        label: 'Last Name',
        requiredMsg: '*Last name is required',
        placeholder: 'Enter Last Name',
    },
    {
        formControlName: 'address',
        type: 'text',
        label: 'Address',
        requiredMsg: '*Address  is required',
        placeholder: 'Enter Address',
    },
    {
        formControlName: 'email',
        type: 'email',
        label: 'Email',
        requiredMsg: '*Email is required',
        patternMsg: 'Invalid Email',
        placeholder: 'Enter Email',
    },
    {
        formControlName: 'city',
        type: 'text',
        label: 'City',
        requiredMsg: '*City name is required',
        placeholder: 'Enter City',
    },

    {
        formControlName: 'date_of_birth',
        label: 'Date of Birth',
        type: 'calendar',
        requiredMsg: '*Date of Birth is required.',
        placeholder: 'Enter Date of Birth.',
    },
    {
        formControlName: 'state_id',
        type: 'dropdown',
        label: 'State',
        requiredMsg: '*State is required',
        placeholder: 'Select State',
        optionLabel: 'state_name',
        optionValue: 'id',
        optionListKey: 'states',
    },
    {
        formControlName: 'user_sub_type',
        type: 'dropdown',
        label: 'Type',
        requiredMsg: '*Type is required',
        placeholder: 'Select Type',
        optionLabel: 'type',
        optionValue: 'id',
        optionListKey: 'contractorSubType',
    },
    {
        formControlName: 'zipcode',
        label: 'Zipcode',
        type: 'text',
        requiredMsg: 'Zipcode is required',
        patternMsg: 'Invalid Zipcode',
        placeholder: 'Enter Zipcode',
        maxLength: 6,
    },
    {
        formControlName: 'gender',
        type: 'dropdown',
        label: 'Gender',
        requiredMsg: '*Gender is required',
        placeholder: 'Select Gender',
        optionLabel: 'gender',
        optionValue: 'id',
        optionListKey: 'gender',
    },
];

export let formActions: any = {
    cancelBtn: {
        styleClass: '',
        label: 'Cancel',
    },
    okbtn: {
        styleClass: '',
        label: ' Account',
    },
    initailData: {
        states: [],
        gender: GenderEnum,
        contractorSubType: ContractorTypes,
        carbase: [],
        mpList: [],
        drivers: [],
        isInitialDataLoaded: false,
        elevator: [
            { value: '1', label: "Yes" },
            { value: '0', label: "No" },
        ],
        pickup_point: [
            {
                "label": "Doorman",
                "value": "doorman"
            },
            {
                "label": "Frontdesk",
                "value": "frontdesk"
            },
            {
                "label": "Inside door",
                "value": "inside_the_door"
            },
            {
                "label": "Apartment door",
                "value": "apartment_door"
            }
        ]
    },
};
