import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

/** Google Place Autocomplete API Key */
const GOOGLE_MAPS_API_KEY = environment.googlePlaceKey;

export type Maps = typeof google.maps;

declare var google: any;
export let MyOverlay: any; // declare and use this from other ts file

@Injectable()
export class GmapService {
    defaultAvoidHighways: boolean = true;
    defaultAvoidTolls: boolean = false;
    /** Load Google Map API */
    public readonly api = this.load();

    constructor() { }

    /** Load Google Map Function */
    private load(): Promise<Maps> {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        // tslint:disable-next-line:no-bitwise
        const callbackName = `GooglePlaces_cb_` + ((Math.random() * 1e9) >>> 0);
        script.src = this.getScriptSrc(callbackName);
        interface MyWindow { [name: string]: Function; };
        const myWindow: MyWindow = window as any;
        const promise = new Promise((resolve, reject) => {
            myWindow[callbackName] = resolve;
            script.onerror = reject;
        });
        document.body.appendChild(script);
        return promise.then(() => google.maps);
    }

    /** Load Google Map Scripts */
    private getScriptSrc(callback: string): string {
        interface QueryParams { [key: string]: string; };
        const query: QueryParams = {
            v: '3',
            callback,
            key: GOOGLE_MAPS_API_KEY,
            libraries: 'places',
        };
        const params = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
        return `//maps.googleapis.com/maps/api/js?${params}&language=en`;
    }



    secondsToHms(seconds: number): string {
        seconds = Number(seconds);
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        // var s = Math.floor(seconds % 3600 % 60);

        const hDisplay = h > 0 ? h + (h == 1 ? ' hr ' : ' hrs ') : '';
        const mDisplay = m > 0 ? m + (m == 1 ? ' min ' : ' mins ') : '';
        // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay;
    }

    initMyOverlay() {
        class MyOverlayCls extends google.maps.OverlayView {
            position: any;
            element: any;
            map: any;

            constructor(@Inject('position') position: any,
                @Inject('element') element: any,
                @Inject('map') map: any) {
                super();
                this.position = position;
                this.element = element;
                this.map = map;
                this['setMap'](map);

            }

            onAdd(): void {
                const panes = this['getPanes']();
                panes.overlayLayer.style['zIndex'] = 1001;
                panes.overlayLayer.appendChild(this.element);
            }

            draw(): void {
                const overlayProjection = this['getProjection']();
                const point = overlayProjection.fromLatLngToDivPixel(this.position);

                const elementWidth = this.element.offsetWidth;
                const elementHeight = this.element.offsetHeight;

                const offsetX = -elementWidth / 2; // To position the element horizontally centered
                const offsetY = -elementHeight - 30; // To position the element at the bottom

                const left = point.x + offsetX;
                const top = point.y + offsetY;

                this.element.style.left = left + 'px';
                this.element.style.top = top + 'px';
                this.element.style.zIndex = '1000'; // Set a higher z-index value for the overlay element
            }

            onRemove(): void {
                this.element.parentNode.removeChild(this.element);
            }
        }
        MyOverlay = MyOverlayCls; //assign class to variable
    }

}
