interface IFormHtml {
    formControlName: string,
    label: string,
    type: string,
    requiredMsg?: string,
    patternMsg?: string,
    placeholder: string,
    optionLabel?: string,
    optionValue?: string,
    formatMobile?: boolean,
    maxLength?: number,
}

/** Enrollee Profile  */
export const enrolleeProfile: IFormHtml[] = [
    {
        formControlName: "first_name",
        label: "First Name",
        type: "text",
        requiredMsg: "*First Name is required",
        placeholder: "Enter First Name"
    },
    {
        formControlName: "last_name",
        type: "text",
        label: "Last Name",
        requiredMsg: "*Last name is required",
        placeholder: "Enter Last Name"
    },
    {
        formControlName: "email",
        type: "email",
        label: "Email",
        requiredMsg: "*Email is required",
        patternMsg: "Invalid Email",
        placeholder: "Enter Email"
    },
    {
        formControlName: "mobile",
        type: "inputMask",
        label: "Phone",
        requiredMsg: "*Phone no. is required",
        placeholder: "Enter mobile number",
        formatMobile: true
    },
    {
        formControlName: "state_id",
        type: "dropdown",
        label: "State",
        requiredMsg: "*State is required",
        placeholder: "Select State",
        optionLabel: "state_name",
        optionValue: "id",
    },
    {
        formControlName: "zipcode",
        label: "Zipcode",
        type: "text",
        requiredMsg: "Zipcode is required",
        placeholder: "Enter Zipcode",
        patternMsg: "Invalid Zipcode",
        maxLength: 6,
    },
    {
        formControlName: "cin_medical_id",
        label: "CIN or Medicaid Number",
        type: "text",
        requiredMsg: "*CIN or Medicaid Number required",
        placeholder: "Enter CIN or Medicaid Number"
    },
    {
        formControlName: "social_security_number",
        label: "Last 4 Digit of Social Security Number",
        type: "text",
        requiredMsg: "*Social Security Number is required and must be at least 4 characters.",
        placeholder: "Enter last 4 digit of Social Security Number"
    },
    {
        formControlName: "social_security_number",
        label: "Last 4 Digit of Social Security Number",
        type: "text",
        requiredMsg: "*Social Security Number is required and must be at least 4 characters.",
        placeholder: "Enter last 4 digit of Social Security Number"
    },
    {
        formControlName: "date_of_birth",
        label: "Date of Birth",
        type: "calendar",
        requiredMsg: "*Date of Birth is required.",
        placeholder: "*Date of Birth is required.",
    },
    {
        formControlName: "gender",
        type: "dropdown",
        label: "Gender",
        requiredMsg: "*Gender is required",
        placeholder: "Select Gender",
        optionLabel: "gender",
        optionValue: "id",
    },
];


/** Medical Provider Profile  */
export const medicalProvider: IFormHtml[] = [
    {
        formControlName: "facility_department",
        label: "Facility/ Practice/ Department",
        type: "text",
        requiredMsg: "*Facility/Practice/Department is required.",
        placeholder: "e.g. General Hospital X-Ray Department"
    },
    {
        formControlName: "email",
        type: "email",
        label: "Email",
        requiredMsg: "*Email is required",
        patternMsg: "Invalid Email",
        placeholder: "Enter Email"
    },
    {
        formControlName: "first_name",
        label: "First Name",
        type: "text",
        requiredMsg: "*First Name is required",
        placeholder: "Enter First Name"
    },
    {
        formControlName: "last_name",
        type: "text",
        label: "Last Name",
        requiredMsg: "*Last name is required",
        placeholder: "Enter Last Name"
    },
    {
        formControlName: "mobile",
        type: "inputMask",
        label: "Work Number",
        requiredMsg: "*Enter telephone number (work) is required",
        placeholder: "Enter telephone number (work)",
        formatMobile: true
    },
    {
        formControlName: "state_id",
        type: "dropdown",
        label: "State",
        requiredMsg: "*State is required",
        placeholder: "Select State",
        optionLabel: "state_name",
        optionValue: "id",
    },
    {
        formControlName: "zipcode",
        label: "Zipcode",
        type: "text",
        requiredMsg: "Zipcode is required",
        placeholder: "Enter Zipcode",
        patternMsg: "Invalid Zipcode",
        maxLength: 6,
    },
];

/** Transportation Provider   */
export const transportationProvider: IFormHtml[] = [
    {
        formControlName: "service_provider_name",
        label: "Transportation Provider Name",
        type: "text",
        requiredMsg: "*Provider Name is required.",
        placeholder: "e.g. ABC Transportation Inc"
    },
    {
        formControlName: "medicaid_provider_id",
        label: "Medicaid Provider ID",
        type: "text",
        requiredMsg: "*Medicaid Provider ID is required.",
        placeholder: "Enter Medicaid Provider ID"
    },
    {
        formControlName: "first_name",
        label: "First Name",
        type: "text",
        requiredMsg: "*First Name is required",
        placeholder: "Enter First Name"
    },
    {
        formControlName: "last_name",
        type: "text",
        label: "Last Name",
        requiredMsg: "*Last name is required",
        placeholder: "Enter Last Name"
    },
    {
        formControlName: "email",
        type: "email",
        label: "Email",
        requiredMsg: "*Email is required",
        patternMsg: "Invalid Email",
        placeholder: "Enter Email"
    },
    {
        formControlName: "mobile",
        type: "inputMask",
        label: "Phone",
        requiredMsg: "*Phone no. is required",
        placeholder: "Enter mobile number",
        formatMobile: true
    },
    {
        formControlName: "state_id",
        type: "dropdown",
        label: "State",
        requiredMsg: "*State is required",
        placeholder: "Select State",
        optionLabel: "state_name",
        optionValue: "id",
    },
    {
        formControlName: "zipcode",
        label: "Zipcode",
        type: "text",
        requiredMsg: "Zipcode is required",
        placeholder: "Enter Zipcode",
        patternMsg: "Invalid Zipcode",
        maxLength: 6,
    },
];
