import { ICountry } from "src/app/shared/interfaces/form.interface";

export const form: IForm = {
    primary: {
        labelClass: "text-[13px] text-[#334D6E] mt-1 float-right mr-2",
        inputClass: "text-[13px] text-[#9FA1A4]",
        errorTextClass: "text-red-500	text-xs mt-1",
    }
};

export const dateFormate: dateForm = {
    dateWithTime: 'yyyy-MM-dd HH:mm:ss',
    yyyymmdd: 'yyyy-MM-dd'
}

interface dateForm {
    dateWithTime: string,
    yyyymmdd: string
}

interface IForm {
    primary: IPrimary
}

interface IPrimary {
    labelClass: string,
    inputClass: string,
    errorTextClass: string
}

export const CountryList: Array<ICountry> = [
    {
        label: 'United States (+1)',
        dial_code: '+1',
        flag: "assets/images/united-states-of-america.png"
    },
    {
        label: 'India (+91)',
        dial_code: '+91',
        flag: "assets/images/india-flag-icon.svg"
    }
]