export function getTimeZoneTimeObj(timeZoneOffsetminutes: number) {
    var localdate = new Date();
    var timeZoneDate = new Date(localdate.getTime() + ((localdate.getTimezoneOffset() - timeZoneOffsetminutes) * 60 * 1000));
    return { 'h': timeZoneDate.getHours(), 'm': timeZoneDate.getMinutes(), 's': timeZoneDate.getSeconds() };
}

export function getTimezoneName(): any {
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
        const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

        // by this time `trimmed` should be the timezone's name with some punctuation -
        // trim it from both sides
        return (trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '')).split(" ").map((n) => n[0]).join("");;

    } else {
        // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
        return short;
    }

}