import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from '../layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: "Dashboard" }
      },
      {
        path: '',
        loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule),
        data: { title: "Profile" }
      },
      {
        path: '',
        loadChildren: () => import('../manage-user/manage-user.module').then(m => m.ManageUserModule),
        data: { title: "Users" }
      },
      {
        path: '',
        loadChildren: () => import('../notes/notes.module').then(m => m.NotesModule),
        data: { title: "Notes" }
      },
      {
        path: '',
        loadChildren: () => import('../services/services.module').then(m => m.ServicesModule),
        data: { title: 'Services' }
      },
      {
        path: '',
        loadChildren: () => import('../trips/request.module').then(m => m.RequestModule),
        data: { title: 'Request' }
      },
      {
        path: '',
        loadChildren: () => import('../notification/notfication/notfication.module').then(m => m.NotficationModule),
        data: { title: 'Notifications' }
      },
      {
        path: '',
        loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule),
        data: { title: 'Settings' }
      },
      {
        path: 'preferred/:type',
        loadComponent: () => import('../../@standalone/preferred/preferred.component').then(m => m.PreferredComponent),
        data: { title: 'Preferred' }
      },
      {
        path: 'affiliation',
        loadComponent: () => import('../../@standalone/affiliation/affiliation.component').then(m => m.AffiliationComponent),
        data: { title: 'Affiliation' }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainPageRoutingModule { }
