import { IListHeaderConfig } from "src/app/core/interface/list-header.interface";
import { INotesPriority, INotesTab } from "../interface/notes.interface";
import { IFormHtml } from "src/app/shared/interfaces/form.interface";

export enum NOTES_TYPE {
    Enrollee = 'tenant',
    MedicalProvider = 'MP',
    TransportationProvider = 'TP',
    Driver = 'driver',
    Trips = 'trips'
}

export enum NOTES_TYPE_ID {
    USERDETAILS = 'users_details',
    TRIPDETAILS = 'trip_details'
}

export const notesHeaderConfig: IListHeaderConfig = {
    title: "Notes",
    search: { isSearch: true, paceholder: "Search" },
    export: { isExport: true, text: "Export CSV", type: 0, subBtns: [] },
    add: {
        isAdd: true, btnText: "+  Add Notes", type: 1, subBtns: [
            { label: 'Enrollee' },
            { label: 'Driver' },
            { label: 'Trip' },
        ]
    },
    columns: { colLeftClass: "md:col-3", colRightClass: "md:col-9" }
};

/** Notes Tab */
export const notesTabs: INotesTab[] = [
    { title: 'All Notes', slug: 'all', disabled: false, children: [] },
    { title: 'Enrollee Notes', slug: 'enrolleeNotes', disabled: false, children: [] },
    { title: 'Driver Notes', slug: 'driverNotes', disabled: false, children: [] },
    { title: 'Trip Notes', slug: 'tripNotes', disabled: false, children: [] },
];

export const NotesPriority: INotesPriority[] = [
    { id: 'High', type: "High" },
    { id: 'Medium', type: "Medium" },
    { id: 'Low', type: "Low" }
];

/** Form Actions Config */
export let formActions: any = {
    cancelBtn: {
        styleClass: "",
        label: "Cancel"
    },
    okbtn: {
        styleClass: "",
        label: " Account"
    },
    initailData: {
        priority: NotesPriority,
        tenant: [],
        driver: [],
        trip: []
    }
}

// For the Enrollee Notes Form
export const addNotesFormEnrolle: IFormHtml[] = [
    {
        formControlName: "title",
        label: "Title",
        type: "text",
        requiredMsg: "*Description is required",
        placeholder: "Enter title here"
    },
    {
        formControlName: "priority",
        label: "Priority",
        type: "dropdown",
        requiredMsg: "*Priority is required",
        placeholder: "Select Priority",
        optionLabel: "type",
        optionValue: "id",
        optionListKey: 'priority',
    },
    {
        formControlName: "type_id",
        label: "Select Enrollee",
        type: "dropdown-with-search",
        requiredMsg: "*Enrollee is required",
        placeholder: "Select Enrollee",
        optionListKey: 'tenant',
        optionLabel: 'displayName',
        optionValue: "user_id",
    },
    {
        formControlName: "description",
        label: "Description",
        type: "textarea",
        requiredMsg: "*Description is required",
        placeholder: "Type here"
    },
]

//  For The Driver Notes Form
export const addNotesFormDriver: IFormHtml[] = [
    {
        formControlName: "title",
        label: "Title",
        type: "text",
        requiredMsg: "*Description is required",
        placeholder: "Enter title here"
    },
    {
        formControlName: "priority",
        label: "Priority",
        type: "dropdown",
        requiredMsg: "*Priority is required",
        placeholder: "Select Priority",
        optionLabel: "type",
        optionValue: "id",
        optionListKey: 'priority',
    },
    {
        formControlName: "type_id",
        label: "Select Driver",
        type: "dropdown-with-search",
        requiredMsg: "*Driver is required",
        placeholder: "Select Driver",
        optionListKey: 'driver',
        optionLabel: 'displayName',
        optionValue: "user_id",
    },
    {
        formControlName: "description",
        label: "Description",
        type: "textarea",
        requiredMsg: "*Description is required",
        placeholder: "Type here"
    },
]

// For the Trip Notes Form
export const addNotesFormTrip: IFormHtml[] = [
    {
        formControlName: "title",
        label: "Title",
        type: "text",
        requiredMsg: "*Description is required",
        placeholder: "Enter title here"
    },
    {
        formControlName: "priority",
        label: "Priority",
        type: "dropdown",
        requiredMsg: "*Priority is required",
        placeholder: "Select Priority",
        optionLabel: "type",
        optionValue: "id",
        optionListKey: 'priority',
    },
    {
        formControlName: "type_id",
        label: "Select Trip #",
        type: "dropdown-with-search",
        requiredMsg: "*Trip is required",
        placeholder: "Select Trip",
        optionListKey: 'trip',
        optionLabel: 'displayName',
        optionValue: "trip_id",
    },
    {
        formControlName: "description",
        label: "Description",
        type: "textarea",
        requiredMsg: "*Description is required",
        placeholder: "Type here"
    },
]