<div class="sub-header" [ngClass]="{'shadow-active': isScrollActive}">
    <ul
        class="flex flex-wrap text-sm font-medium text-center border-b overflow-auto rounded-t-lg justify-between items-center">
        <li class="mr-2 pl-5 max-[838px]:p-4">
            <ul class="navbar flex flex-row p-0">
                <li class="nav-item mx-2" *ngFor="let item of menuItems">
                    <a *ngIf="item.userType == 0 || userStatusEnum.ACTIVATED == userDetail.status"
                        class="px-3 py-1 no-underline cursor-pointer" [routerLink]="item.route"
                        [routerLinkActive]="['active']">
                        {{ item.label }}
                    </a>
                </li>
            </ul>
        </li>
        <li>
            <app-clock class="hidden md:block  p-4 sm:ml-4 text-[15px] font-normal text-[#2C71C3]" view="desktop">
            </app-clock>
        </li>
    </ul>
</div>
