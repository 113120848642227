import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { concatMap } from 'rxjs';
import { form } from 'src/app/util/constant/form.config';
import { IState } from 'src/app/core/interface/interface';
import { UserAddressType } from 'src/app/shared/interfaces/user.interface';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { ApiService } from 'src/app/shared/services/http/api.service';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import {
  DynamicDialogModule,
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import {
  CommancssClassesType,
  commancssClasses,
} from 'src/app/util/constant/cssClass.config';
import { GmapService, Maps } from 'src/app/portal/trips/service/gmap.service';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    InputMaskModule,
    DropdownModule,
    CalendarModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    InputNumberModule,
  ],
  providers: [DialogService, GmapService],
})
export class AddressFormComponent implements OnInit {
  @ViewChild('autoComplete') autoComplete!: AutoComplete;
  @ViewChild('mailAddressautoComplete') mailAddressautoComplete!: AutoComplete;
  @ViewChildren('search') public searchElementRef!: QueryList<ElementRef>;
  @ViewChild('mapElement') mapElement!: ElementRef;
  physicalAddressform!: FormGroup;
  @ViewChildren('autoComplete') autoCompletes!: QueryList<AutoComplete>;
  private isDropdownOpen = false;
  mailingAddressform!: FormGroup;
  states: IState[] = [];
  isSubmitting: boolean = false;
  labelClass: string = form.primary.labelClass;
  inputClass: string = form.primary.inputClass;
  errorTextClass: string = form.primary.errorTextClass;
  commanCss: CommancssClassesType = commancssClasses;

  constructor(
    private apiService: ApiService,
    private readonly spinner: SpinnerService,
    private readonly dialogRef: DynamicDialogRef,
    public readonly config: DynamicDialogConfig,
    private toastr: ToastrService,
    private gmapService: GmapService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /** Init Form */
  private initForm(): void {
    this.physicalAddressform = this.config.data.form1;
    this.mailingAddressform = this.config.data.form2;
    // For state update physicalAddressform
    const phstateControle: any = this.physicalAddressform.get('state_id');
    const pfoundStateObject =
      this.config.data.formActions.initailData.states.find(
        (obj: any) => obj.id == phstateControle.value
      );
    phstateControle?.patchValue(pfoundStateObject);

    const pickupPointControle: any = this.physicalAddressform.get('pickup_point');
    const pickupPointbject =
      this.config.data.formActions.initailData.pickup_point.find(
        (obj: any) => obj.value == pickupPointControle.value
      );
    pickupPointControle?.patchValue(pickupPointbject);
    // For state update
    const mlstateControle: any = this.mailingAddressform.get('state_id');
    const mlfoundStateObject =
      this.config.data.formActions.initailData.states.find(
        (obj: any) => obj.id == mlstateControle.value
      );
    mlstateControle?.patchValue(mlfoundStateObject);
  }

  /** Copy Date To Physical Form When Click On Same As a Mailing Address */
  copyDataToPhysicalForm(event: any) {
    if (event.target.checked) {
      // If the checkbox is checked, copy data from physicalAddressform to mailingAddressform
      this.mailingAddressform.patchValue(this.physicalAddressform.value);
    } else {
      // If the checkbox is unchecked, you can clear or reset the mailingAddressform if needed
      this.mailingAddressform.reset();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initializeMap();
    });
  }

  private initAutocomplete(maps: Maps): void {
    this.searchElementRef.forEach((searchElement, index) => {
      let autocomplete = new maps.places.Autocomplete(
        searchElement.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          this.onPlaceChange(autocomplete.getPlace());
        });
      });
    });
  }

  private onPlaceChange(place: google.maps.places.PlaceResult): void {
    if (!place.geometry || !place.geometry.location) {
      return;
    }

    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    // Initialize variables for address components
    let address = '';
    let city = '';
    let state = '';
    let zipCode = '';

    // Extract components from address
    if (place.address_components) {
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number':
            address = `${component.long_name} `;
            break;

          case 'route':
            address += component.long_name;
            break;

          case 'locality':
            city = component.long_name;
            break;

          case 'administrative_area_level_1':
            state = component.long_name;
            break;

          case 'postal_code':
            zipCode = component.long_name;
            break;
        }
      }
    }

    // Patch values to the form
    this.physicalAddressform.patchValue({
      address: address || place.formatted_address, // fallback to formatted_address if components aren't available
      city: city,
      state_id: state, // Note: You might need to map this to your state_id value
      zipcode: zipCode,
      location_lat_long: `${latitude},${longitude}`,
    });
  }

  private initializeMap(): void {
    if (!this.mapElement) {
      return;
    }

    this.gmapService.api
      .then((maps) => {
        try {
          this.initAutocomplete(maps);
        } catch (error) {
          console.error('Error initializing map:', error);
        }
      })
      .catch((error) => {
        console.error('Error loading Google Maps API:', error);
      });
  }

  /** NgOnDestroy */
  ngOnDestroy(): void {
    this.physicalAddressform.reset();
    this.mailingAddressform.reset();
  }

  /** Dialog cancel */
  cancel(): void {
    this.dialogRef.close();
  }

  /** Pysical form */
  get physicalForm() {
    return this.physicalAddressform.controls;
  }

  /** Mailing form */
  get mailingForm() {
    return this.mailingAddressform.controls;
  }

  /** Submit form */
  submit(): void {
    if (this.physicalAddressform.invalid || this.mailingAddressform.invalid) {
      this.isSubmitting = true;
      return;
    }
    this.spinner.showSpinner();
    this.isSubmitting = false;
    const payload1: any = {
      ...this.physicalAddressform.value,
      ...this.config.data.userType,
    };
    payload1.state_id = payload1.state_id?.id;
    payload1.pickup_point = payload1.pickup_point?.value;
    payload1.address_type = UserAddressType.PHYSICAL; // Set the address_type for the PHYSICAL

    const payload2: any = {
      ...this.mailingAddressform.value,
      ...this.config.data.userType,
    };
    payload2.state_id = payload2.state_id?.id;
    payload2.address_type = UserAddressType.MAILING; // Set the address_type for the MAILING

    if (this.config?.data?.omitEmptyValues) {
      Object.keys(payload1).forEach((item: any) => {
        if (!item) delete payload1[item];
      });
      Object.keys(payload2).forEach((item: any) => {
        if (!item) delete payload2[item];
      });
    }
    this.apiService
      .httpPut(this.config.data.submitUrl, payload1)
      .pipe(
        concatMap(() =>
          this.apiService.httpPut(this.config.data.submitUrl, payload2)
        )
    )
      .subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.spinner.hideSpinner();
            if (this.config.data.closeOnComplete) {
              this.dialogRef.close(res?.data);
              this.toastr.showSuccess(res.message);
            }
          }
        },
        error: () => this.spinner.hideSpinner(),
      });
  }

  /** Filter Item */
  filterItems(event: AutoCompleteCompleteEvent, type: string) {
    if (type == 'physical') {
      this.autoComplete.show();
    } else {
      this.mailAddressautoComplete.show();
    }
    this.config.data.formActions.initailData.states = [
      ...this.config.data.formActions.initailData.states,
    ];
  }

  onHide() {
    this.isDropdownOpen = false;
  }

  /** On focus */
  onshow(event: any, type: string) {
    // Prevent multiple triggers
    if (this.isDropdownOpen) return;

    this.isDropdownOpen = true;
    setTimeout(() => {
      const autoComplete = this.autoCompletes.find((ac) =>
        ac.el.nativeElement.contains(event.target)
      );
      if (autoComplete) {
        autoComplete.show();
        // Update the data based on type
        this.updateDropdownData(type);
      }
    });
  }

  private updateDropdownData(type: string) {
    switch (type) {
      case 'state':
        this.config.data.formActions.initailData.states = [
          ...this.config.data.formActions.initailData.states,
        ];
        break;
      case 'gender':
        this.config.data.formActions.initailData.gender = [
          ...this.config.data.formActions.initailData.gender,
        ];
        break;
      case 'type':
        this.config.data.formActions.initailData.contractorSubType = [
          ...this.config.data.formActions.initailData.contractorSubType,
        ];
        break;
  // Add more cases as needed
    }
  }
}
