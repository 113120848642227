import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appDateFormat]',
    standalone: true,
})
export class DateFormatDirective {
    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        let value = input.value.replace(/\D/g, ''); // Remove non-numeric characters
        if (value.length >= 2) {
            value = `${value.slice(0, 2)}/${value.slice(2)}`;
        }
        if (value.length >= 5) {
            value = `${value.slice(0, 5)}/${value.slice(5)}`;
        }
        input.value = value;
    }
}
