import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { concatMap } from 'rxjs';
import { form } from 'src/app/util/constant/form.config';
import { IState } from 'src/app/core/interface/state.interface';
import { UserAddressType } from 'src/app/shared/interfaces/user.interface';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { ApiService } from 'src/app/shared/services/http/api.service';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicDialogModule, DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { CommancssClassesType, commancssClasses } from 'src/app/util/constant/cssClass.config';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  standalone: true,
  imports: [CommonModule, AutoCompleteModule, InputMaskModule, DropdownModule, CalendarModule, InputTextModule, FormsModule, ReactiveFormsModule, DynamicDialogModule, InputNumberModule],
  providers: [DialogService]

})
export class AddressFormComponent implements OnInit {
  @ViewChild('autoComplete') autoComplete!: AutoComplete;
  @ViewChild('mailAddressautoComplete') mailAddressautoComplete!: AutoComplete;
  physicalAddressform!: FormGroup;
  mailingAddressform!: FormGroup;
  states: IState[] = [];
  isSubmitting: boolean = false;
  labelClass: string = form.primary.labelClass;
  inputClass: string = form.primary.inputClass;
  errorTextClass: string = form.primary.errorTextClass;
  commanCss: CommancssClassesType = commancssClasses;

  constructor(private apiService: ApiService, private readonly spinner: SpinnerService, private readonly dialogRef: DynamicDialogRef, public readonly config: DynamicDialogConfig, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initForm();
  }

  /** Init Form */
  private initForm(): void {
    this.physicalAddressform = this.config.data.form1;
    this.mailingAddressform = this.config.data.form2;
    // For state update physicalAddressform
    const phstateControle: any = this.physicalAddressform.get("state_id");
    const pfoundStateObject = this.config.data.formActions.initailData.states.find((obj: any) => obj.id == phstateControle.value);
    phstateControle?.patchValue(pfoundStateObject);
    // For state update
    const mlstateControle: any = this.mailingAddressform.get("state_id");
    const mlfoundStateObject = this.config.data.formActions.initailData.states.find((obj: any) => obj.id == mlstateControle.value);
    mlstateControle?.patchValue(mlfoundStateObject);
  }

  /** Copy Date To Physical Form When Click On Same As a Mailing Address */
  copyDataToPhysicalForm(event: any) {
    if (event.target.checked) {
      // If the checkbox is checked, copy data from physicalAddressform to mailingAddressform 
      this.mailingAddressform.patchValue(this.physicalAddressform.value);
    } else {
      // If the checkbox is unchecked, you can clear or reset the mailingAddressform if needed
      this.mailingAddressform.reset();
    }
  }

  /** NgOnDestroy */
  ngOnDestroy(): void {
    this.physicalAddressform.reset();
    this.mailingAddressform.reset();
  }

  /** Dialog cancel */
  cancel(): void {
    this.dialogRef.close();
  }

  /** Pysical form */
  get physicalForm() {
    return this.physicalAddressform.controls;
  }

  /** Mailing form */
  get mailingForm() {
    return this.mailingAddressform.controls;
  }

  /** Submit form */
  submit(): void {
    if (this.physicalAddressform.invalid || this.mailingAddressform.invalid) {
      this.isSubmitting = true;
      return;
    }
    this.spinner.showSpinner();
    this.isSubmitting = false;
    const payload1: any = { ...this.physicalAddressform.value, ...this.config.data.userType };
    payload1.state_id = payload1.state_id?.id;
    payload1.address_type = UserAddressType.PHYSICAL; // Set the address_type for the PHYSICAL

    const payload2: any = { ...this.mailingAddressform.value, ...this.config.data.userType };
    payload2.state_id = payload2.state_id?.id;
    payload2.address_type = UserAddressType.MAILING; // Set the address_type for the MAILING

    if (this.config?.data?.omitEmptyValues) {
      Object.keys(payload1).forEach((item: any) => {
        if (!item) delete payload1[item];
      });
      Object.keys(payload2).forEach((item: any) => {
        if (!item) delete payload2[item];
      });
    }
    this.apiService.httpPut(this.config.data.submitUrl, payload1)
      .pipe(
        concatMap(() => this.apiService.httpPut(this.config.data.submitUrl, payload2))
      )
      .subscribe(
        {
          next: (res: any) => {
            if (res.status == 200) {
              this.spinner.hideSpinner();
              if (this.config.data.closeOnComplete) {
                this.dialogRef.close(res?.data);
                this.toastr.showSuccess(res.message);
              }
            }
          },
          error: () => this.spinner.hideSpinner()
        }
      );
  }

  /** On focus */
  onshow(event: any, type: string) {
    if (type == 'physical') {
      this.autoComplete.show();
    } else {
      this.mailAddressautoComplete.show();
    }
    this.config.data.formActions.initailData.states = [...this.config.data.formActions.initailData.states];
  }

  /** Filter Item */
  filterItems(event: AutoCompleteCompleteEvent, type: string) {
    if (type == 'physical') {
      this.autoComplete.show();
    } else {
      this.mailAddressautoComplete.show();
    }
    this.config.data.formActions.initailData.states = [...this.config.data.formActions.initailData.states];
  }
}
