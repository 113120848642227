<nav class="dashboard-header email-verified">
    <div class="flex flex-wrap items-center justify-between mx-auto p-4">
        <a class="flex items-center leading-3">
            <div class="flex flex-col cursor-pointer" routerLink="/dashboard">
                <img src="/assets/portal/icons/header_logo.png" class="header-logo" alt="Logo" />
            </div>
        </a>
        <a class="min-[447px]:hidden mobile-menu-item">
            <p-avatar (click)="op.toggle($event)"
                image="{{userDetails.profile_picture ? userDetails.profile_picture : '/assets/portal/images/userImage.jpg'}}"
                styleClass="cursor-pointer" shape="circle">
            </p-avatar>
        </a>
        <div class="flex items-center md:order-2 max-[446px]:hidden">
            <p-avatar image="/assets/portal/icons/alert-red.png" styleClass="mr-7 cursor-pointer" severity="danger"
                [style]="{ 'width': '24px','height':'24px' }" (click)="notification.toggle($event)">
            </p-avatar>
            <p-avatar image="/assets/portal/icons/support1.png" [style]="{ 'width': '24px','height':'24px' }"
                styleClass="mr-7 cursor-pointer" shape="circle">
            </p-avatar>
            <p-avatar (click)="op.toggle($event)"
                image="{{userDetails.profile_picture ? userDetails.profile_picture : '/assets/portal/images/userImage.jpg'}}"
                styleClass="mr-3 cursor-pointer" shape="circle">
            </p-avatar>
            <p-overlayPanel #op styleClass="overlay-nav">
                <ul class="p-0 m-0 list-none w-10rem" (click)="op.hide()">
                    <li class="cursor-pointer text-[12px] text-[#FFFFFF] bg-[#3380a9] px-7 py-3">
                        <h5 class="font-medium text-[14px]"> {{userDetails?.first_name}} {{userDetails?.last_name}} </h5>
                        <p> {{ userDetails?.service_provider_name }} </p>
                        <p class="text-[10px]"> {{sharedService.getUserTypeLabel(userDetails)}} </p>
                    </li>
                    <hr>
                    <li class="cursor-pointer text-[12px] text-[#828282] px-7 py-3 min-[447px]:hidden">
                        Alerts
                    </li>
                    <li class="cursor-pointer text-[12px] text-[#828282] px-7 py-3 min-[447px]:hidden">
                        Help
                    </li>
                    <hr class="min-[447px]:hidden">
                    <li class="cursor-pointer text-[12px] text-[#828282] px-7 py-3" (click)="gotoProfile()">
                        See Profile
                    </li>
                    <li class="cursor-pointer text-[12px] text-[#828282] px-7 py-3" (click)="visible = true">
                        Logout
                    </li>
                    <hr class="block md:hidden">
                    <li class="cursor-pointer px-7 py-2 block md:hidden">
                        <app-clock view="mobile"></app-clock>
                    </li>
                </ul>
            </p-overlayPanel>
        </div>
    </div>
</nav>

<!-- Notification  Overlay Panel Start-->
<p-overlayPanel #notification styleClass="overlay-nav-notification" [style]="{ width: '400px' }">
    <ul class="p-0 m-0 list-none w-10rem" (click)="notification.hide()">
        <li class="cursor-pointer text-[14px] text-[#FFFFFF] bg-[#5e9ab9] px-7 py-6 flex justify-between">
            <h5 class="font-medium">
                Notification
            </h5>
            <div class="bg-[#FFFFFF] text-white rounded-full w-5 h-5 flex items-center justify-center">
                <span class="text-[#6F6C6C] text-[13px]">
                    {{notificationCount}}
                </span>
            </div>
        </li>
        <div class="h-[400px] overflow-y-auto">
            <ng-container *ngFor="let list of notifications">
                <li class="cursor-pointer text-[12px]  px-7 py-3"
                    [ngClass]="{'notification-unread' : list.is_read === '0'}">
                    <p class="text-[#334D6E]">
                        {{list.createdAt | date : 'MMM, dd, yyyy, hh:mm:ss'}}
                    </p>
                    <p class="text-[#828282]">
                        {{list.description}}
                    </p>
                </li>
                <hr>
            </ng-container>
            <ng-container *ngIf="notifications.length === 0 && !isSkeletonVisible">
                <div class="text-center mt-5 text-[12px] text-[#334D6E]">
                    No Notification Yet!
                </div>
            </ng-container>
            <div class="m-2">
                <app-skeleton *ngIf="isSkeletonVisible" [styleClass]="'w-full mb-2'" [height]="'3rem'"></app-skeleton>
                <app-skeleton *ngIf="isSkeletonVisible" [styleClass]="'w-full mb-2'" [height]="'3rem'"></app-skeleton>
                <app-skeleton *ngIf="isSkeletonVisible" [styleClass]="'w-full mb-2'" [height]="'3rem'"></app-skeleton>
                <app-skeleton *ngIf="isSkeletonVisible" [styleClass]="'w-full mb-2'" [height]="'3rem'"></app-skeleton>
                <app-skeleton *ngIf="isSkeletonVisible" [styleClass]="'w-full mb-2'" [height]="'3rem'"></app-skeleton>
                <app-skeleton *ngIf="isSkeletonVisible" [styleClass]="'w-full mb-2'" [height]="'3rem'"></app-skeleton>
                <app-skeleton *ngIf="isSkeletonVisible" [styleClass]="'w-full mb-2'" [height]="'3rem'"></app-skeleton>
            </div>
        </div>
        <hr>
        <div class="flex justify-between px-7 py-6">
            <button class="text-[13px] text-[#334D6E] bg-[#FFFFFF] font-medium" type="button">
                Dismiss
            </button>
            <button class="text-[13px] ml-10 mr-5 text-[#2C71C3] bg-[#FFFFFF] font-medium" type="button"
                routerLink="/notifications">
                View more
            </button>
        </div>
    </ul>
</p-overlayPanel>
<!-- Notification  Overlay Panel End-->


<!-- Doc upload modal -->
<div class="card flex justify-content-center signOut-dialog">
    <p-dialog header="Header" [(visible)]="visible" [modal]="true" [breakpoints]="{ '960px': '75vw' }"
        [style]="{ width: '35vw' }">
        <ng-template pTemplate="header">
            <span class="text-[18px] text-[#FFFFFF] font-normal">
                Sign Out
            </span>
        </ng-template>
        <div>
            <h5 class="mt-[45px] text-[13px] text-[#334D6E] font-medium">
                Are you sure you want to sign out?
            </h5>
        </div>
        <ng-template pTemplate="footer">
            <div class="text-right mt-5">
                <button type="button" (click)="visible = false" class="{{commanCss?.dialogCancelBtn}}">
                    Cancel
                </button>
                <button type="submit" class="{{commanCss?.dialogSubmitBtn}}" (click)="logout()">
                    Sign Out
                </button>
            </div>
        </ng-template>
    </p-dialog>
</div>
