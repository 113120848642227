import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { ClockComponent } from 'src/app/@standalone/clock/clock.component';
import { AuthLayoutComponent } from './app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './app-layout/main-layout/main-layout.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { HeaderComponent } from './header/header.component';

/****************** PRIMENG MODULES  *******************/
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { MenuModule } from 'primeng/menu';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonComponent } from 'src/app/shared/components/skeleton/skeleton.component';

const PRIME_MODULES = [
  ToastModule,
  DropdownModule,
  MenuModule,
  ToastModule,
  DropdownModule,
  AvatarGroupModule,
  AvatarModule,
  BadgeModule,
  DialogModule,
  ConfirmDialogModule,
  OverlayPanelModule
]

@NgModule({
  declarations: [
    AuthLayoutComponent,
    MainLayoutComponent,
    SubHeaderComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerComponent,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    ClockComponent,
    SkeletonComponent,
    ...PRIME_MODULES
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [SpinnerService, DatePipe]
})
export class LayoutModule { }
