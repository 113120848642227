
export enum UserTypeEnum {
    TENANT = 'tenant',
    MEDICAL_PROVIDER = 'medical-provider',
    SERVICE_PROVIDER = 'transportation-provider',
    REPRESENTATIVE_OF_ENROLLEE = 'representative'
}

export enum UserStatusEnum {
    UNVERIFIED = '1',
    VERIFIED = '2',
    ACTIVATED = '3',
    DEACTIVATED = '4',
    DELETED = '5',
    BLOCK = '6',
}

export enum UserTypeIdEnum {
    ADMIN = '1',
    TENANT = '2',
    MEDICAL_PROVIDER = '3',
    SERVICE_PROVIDER = '4',
    DRIVER = '5',
    VEHICLE = '6',
}

export enum UserTypeIdForAuth {
    TENANT = '2',
    MEDICAL_PROVIDER = '3',
    SERVICE_PROVIDER = '4'
}

export enum UserAddressType {
    PHYSICAL = 'physical',
    MAILING = 'mailing'
}

export const GenderEnum: any = [
    { id: 'M', gender: "Male" },
    { id: 'F', gender: "Female" },
    { id: 'O', gender: "Other" }
]