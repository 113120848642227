<div class="dialog-container stand-alone-form">
    <div class="grid grid-cols-12 gap-4 mr-10">
        <div class="col-span-6 max-[765px]:col-span-12">
            <form class="p-6 driver-create" [formGroup]="physicalAddressform">
                <h5 class="p-3 pt-0 text-[14px] text-[#334D6E] font-medium ml-10">
                    Office Address
                </h5>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 max-[765px]:col-span-12" *ngFor="let item of config.data.formHTML1">
                        <div class="grid grid-cols-12">
                            <div class="col-span-4">
                                <label class="{{labelClass}}" [ngClass]="{'mt-[13px]': item.label == 'Phone'}">
                                    {{ item.label }}
                                </label>
                            </div>
                            <div class="col-span-8">
                                <ng-container *ngIf="item.type=='text'">
                                    <input pInputText type="text" [placeholder]="item.placeholder"
                                        [formControlName]="item.formControlName" class="{{inputClass}}"
                                        [maxlength]="item?.maxLength">
                                </ng-container>
                                <ng-container *ngIf="item.type=='inputNumber'">
                                    <p-inputNumber styleClass="number-input" [placeholder]="item.placeholder"
                                        [formControlName]="item.formControlName" inputStyleClass="{{inputClass}}">
                                    </p-inputNumber>
                                </ng-container>
                                <ng-container *ngIf="item.type=='dropdown'">
                                    <ng-container *ngIf="item.label=='State'">
                                        <p-autoComplete #autoComplete (onFocus)="onshow($event,'physical')"
                                            (completeMethod)="filterItems($event,'state')"
                                            [formControlName]="item.formControlName"
                                            [suggestions]="config.data.formActions.initailData.states"
                                            class="p-inputtext-sm w-full" field="state_name" [readonly]="true"
                                            [dropdown]="true" [placeholder]="item.placeholder" appendTo="body">
                                        </p-autoComplete>
                                    </ng-container>
                                </ng-container>
                                <div *ngIf="isSubmitting && physicalForm[item.formControlName].invalid && physicalForm[item.formControlName].errors"
                                    class="{{errorTextClass}}">
                                    <ng-container *ngIf="item?.requiredMsg">
                                        <div [hidden]="!physicalForm[item.formControlName].errors!['required']">
                                            {{item.requiredMsg}}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="item?.patternMsg">
                                        <div
                                            [hidden]="!physicalForm[item.formControlName].errors!['pattern']  && physicalForm[item.formControlName].errors!['required']">
                                            {{ item.patternMsg}}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-span-6 max-[765px]:col-span-12 border-l border-gray-300">
            <form class="p-6 driver-create" [formGroup]="mailingAddressform">
                <div class="flex justify-between">
                    <h5 class="p-3 pt-0 text-[14px] text-[#334D6E] font-medium ml-10">
                        Mailing Address
                    </h5>
                    <div class="flex items-center">
                        <label for="remember" class="text-[13px]">
                            Same as home
                        </label>
                        <div class="flex items-center ml-2">
                            <input id="remember" type="checkbox" value="" (change)="copyDataToPhysicalForm($event)"
                                class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800">
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 max-[765px]:col-span-12" *ngFor="let item of config.data.formHTML2">
                        <div class="grid grid-cols-12">
                            <div class="col-span-4">
                                <label class="{{labelClass}}" [ngClass]="{'mt-[13px]': item.label == 'Phone'}">
                                    {{ item.label }}
                                </label>
                            </div>
                            <div class="col-span-8">
                                <ng-container *ngIf="item.type=='text'">
                                    <input pInputText type="text" [placeholder]="item.placeholder"
                                        [formControlName]="item.formControlName" class="{{inputClass}}"
                                        [maxlength]="item?.maxLength">
                                </ng-container>
                                <ng-container *ngIf="item.type=='inputNumber'">
                                    <p-inputNumber styleClass="number-input" [placeholder]="item.placeholder"
                                        [formControlName]="item.formControlName" inputStyleClass="{{inputClass}}">
                                    </p-inputNumber>
                                </ng-container>
                                <ng-container *ngIf="item.type=='dropdown'">
                                    <ng-container *ngIf="item.label=='State'">
                                        <p-autoComplete #mailAddressautoComplete (onFocus)="onshow($event,'')"
                                            (completeMethod)="filterItems($event,'mailing')"
                                            [formControlName]="item.formControlName"
                                            [suggestions]="config.data.formActions.initailData.states"
                                            class="p-inputtext-sm w-full" field="state_name" [readonly]="true"
                                            [dropdown]="true" [placeholder]="item.placeholder" appendTo="body">
                                        </p-autoComplete>
                                    </ng-container>
                                </ng-container>
                                <div *ngIf="isSubmitting && mailingForm[item.formControlName].invalid && mailingForm[item.formControlName].errors"
                                    class="{{errorTextClass}}">
                                    <ng-container *ngIf="item?.requiredMsg">
                                        <div [hidden]="!mailingForm[item.formControlName].errors!['required']">
                                            {{item.requiredMsg}}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="item?.patternMsg">
                                        <div
                                            [hidden]="!mailingForm[item.formControlName].errors!['pattern'] && mailingForm[item.formControlName].errors!['required']">
                                            {{item.patternMsg}}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="text-right processbutton">
        <button type="button" (click)="cancel()" class="{{commanCss?.dialogCancelBtn}} mr-5">
            {{config.data.formActions.cancelBtn.label}}
        </button>
        <button type="submit" class="{{commanCss?.dialogSubmitBtn}} mr-10" (click)="submit()">
            {{ config.data.mode === 'update' ? 'Update' : 'Create' }}
        </button>
    </div>
</div>