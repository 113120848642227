import { IFormHtml } from "src/app/shared/interfaces/form.interface";

// Array of form field configurations for a vehicle form
export const vehicleFormHtml: IFormHtml[] = [
	{
		formControlName: "vehicle_plate",
		label: "Vehicle Plate",
		type: "text",
		requiredMsg: "*Vehicle plate is required",
		placeholder: "Enter Vehicle Plate",
	},
	{
		formControlName: "make",
		label: "Make",
		type: "text",
		requiredMsg: "*Make is required",
		placeholder: "Enter Make",
	},
	{
		formControlName: "vin",
		type: "text",
		label: "VIN",
		requiredMsg: "*VIN is required",
		placeholder: "Enter VIN",
	},
	{
		formControlName: "model",
		label: "Model",
		type: "text",
		requiredMsg: "*Model is required.",
		placeholder: "Enter Model",
	},
	{
		formControlName: "no_of_seat",
		type: "inputNumber",
		label: "No of Seats",
		requiredMsg: "*No of seat is required",
		placeholder: "Enter no of seats",
	},
	{
		formControlName: "year",
		label: "Year",
		type: "text",
		requiredMsg: "*Year is required.",
		placeholder: "Enter Year",
		maxLength: 4,
	},
	{
		formControlName: "state_id",
		type: "dropdown",
		label: "State",
		requiredMsg: "*State is required",
		placeholder: "Select State",
		optionLabel: "state_name",
		optionValue: "id",
		optionListKey: 'states',
	},
	{
		formControlName: "color",
		label: "Color",
		type: "text",
		requiredMsg: "*Color is required.",
		placeholder: "Enter Color",
	},
	{
		formControlName: "expiration_date",
		label: "Expiration Date",
		type: "calendar",
		requiredMsg: "*Expiration date is required.",
		placeholder: "Enter Expiration Date",
		dateFormat: "yy-mm-dd",
		dateFormatValue: "yyyy-mm-dd",
	},
];

// Array of form field configurations for vehicle drivers selection
export const vehicleDriversFormHtml: IFormHtml[] = [
	{
		formControlName: "drivers_id",
		type: "multi-dropdown",
		label: "Select Driver",
		requiredMsg: "*Please select at least one Driver",
		placeholder: "Select Driver",
		optionLabel: "displayName",
		optionValue: "user_id",
		optionListKey: 'drivers',
	},
];
