import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { ApiService } from '../../services/http/api.service';
import { ToastrService } from '../../services/toastr.service';
import { SpinnerService } from '../../services/spinner.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommancssClassesType, commancssClasses } from 'src/app/util/constant/cssClass.config';

@Component({
  selector: 'app-reusable-dialog',
  templateUrl: './reusable-dialog.component.html',
  styleUrls: ['./reusable-dialog.component.scss']
})
export class ReusableDialogComponent implements OnInit {
  message: string | any;
  form!: FormGroup | any;
  userData: any;
  countdown: number = 0; // Initial countdown time in seconds
  countdownInterval: any; // Variable to store the interval
  commanCss: CommancssClassesType = commancssClasses;

  constructor(private fb: FormBuilder, private sharedService: SharedService, private apiService: ApiService, private toastr: ToastrService, private readonly spinner: SpinnerService, private readonly dialogRef: DynamicDialogRef, public readonly config: DynamicDialogConfig) { }

  ngOnInit() {
    // Initialize component data
    this.message = this.config.data.modalMessage;
    this.userData = this.sharedService.getUser();

    // Create a form group with validators
    this.form = this.fb.group({
      user_id: [this.userData.user_id],
      otp_type: ['email'],
      otp: [null, [Validators.required, Validators.pattern(/^\d{4}$/)]],
    });

    // Automatically trigger OTP resend after 200ms
    setTimeout(() => {
      this.resendOtp();
    }, 200);
  }

  /** Dialog cancel */
  cancel(): void {
    this.dialogRef.close(false);
  }

  /** Submit OTP */
  submit() {
    this.sharedService.markFormGroupAsTouched(this.form);
    if (this.form.valid) {
      // Send OTP verification request
      this.spinner.showSpinner();
      this.apiService.httpPost('verify_otp', this.form.value).subscribe({
        next: (res: any) => {
          if (res.success && (res.status === 200 || res.status === 201)) {
            this.spinner.hideSpinner();
            this.userData.is_email_verified = "1";
            this.sharedService.updateData(this.userData);
            this.dialogRef.close(true);
          } else {
            this.spinner.hideSpinner();
          }
        },
        error: () => {
          this.spinner.hideSpinner();
        }
      })
    }
  }

  /** Resend OTP */
  resendOtp() {
    if (this.countdown == 0) {
      // Prepare payload for resending OTP
      const payload = {
        user_id: this.form.value.user_id,
        otp_type: "email"
      }
      // Send OTP resend request
      this.spinner.showSpinner();
      this.apiService.httpPost('send_otp', payload).subscribe({
        next: (res: any) => {
          if (res.success && (res.status === 200 || res.status === 201)) {
            this.spinner.hideSpinner();
            this.countdown = 60;
            // Restart the countdown
            this.startCountdown();
          }
        },
        error: (error: any) => {
          this.spinner.hideSpinner();
          // Handle error
        }
      });
    }
  }

  /** Resend OTP timer */
  startCountdown() {
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        // Countdown reached zero, you can handle what to do here
        clearInterval(this.countdownInterval);
      }
    }, 1000); // Update the countdown every 1 second
  }
}
