import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/http/api.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  constructor(private apiService: ApiService, private sharedService: SharedService) {
  }

  ngOnInit() {
    this.getUserData();
  }

  /** Get user data */
  getUserData() {
    this.apiService.httpGet('profile').subscribe({
      next: (data: any) => {
        if (data.success && (data.status === 200 || data.status === 201)) {
          this.sharedService.updateData(data.data);
        }
      },
      error: (error: any) => {
        console.log(error)
      }
    })
  }

}
