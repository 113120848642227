import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { IMenu, PORTAL_MENU_TENANT, PORTAL_MENU_MP, PORTAL_MENU_SP, PORTAL_MENU_CONTRACTOR } from '../layout.config';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UserStatusEnum, UserTypeIdEnum } from 'src/app/shared/interfaces/user.interface';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

interface PreferredOption {
  label: string;
  id: string;
}

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})

export class SubHeaderComponent {
  menuItems: IMenu[] = [];
  userDetail: any;
  UserTypeIdEnum!: UserTypeIdEnum;
  userStatusEnum: any;
  private userDataSubscription: Subscription | any;
  isScrollActive = false;
  preferredOptions: PreferredOption[] = [
    {
      label: 'Service Provider',
      id: 'service-provider'
    },
    {
      label: 'Contractor',
      id: 'contractor'
    }
  ];

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.scrollY > 0) {
      this.isScrollActive = true;
    } else {
      this.isScrollActive = false;
    }
  }

  constructor(private readonly cd: ChangeDetectorRef, public sharedService: SharedService, private router: Router) {
    this.userStatusEnum = UserStatusEnum;
  }

  ngOnInit(): void {
    this.userDataSubscription = this.sharedService.getUserData().subscribe(userData => {
      this.userDetail = userData;
    });
    this.userDetail = this.sharedService.getUser();
    console.log(this.userDetail);
    switch (this.userDetail?.user_type) {
      case UserTypeIdEnum.TENANT:
        this.menuItems = PORTAL_MENU_TENANT;
        break;
      case UserTypeIdEnum.MEDICAL_PROVIDER:
        this.menuItems = PORTAL_MENU_MP;
        break;
      case UserTypeIdEnum.SERVICE_PROVIDER:
        this.menuItems = PORTAL_MENU_SP;
        break;
      case UserTypeIdEnum.CONTRACTOR:
        this.menuItems = PORTAL_MENU_CONTRACTOR;
        break;
    }
  }

  ngOnDestroy() {
    this.userDataSubscription.unsubscribe();
  }

  handlePreferredOption(option: string): void {
    this.router.navigate(['/preferred', option]);
  }

  get isPreferredActive(): boolean {
    return this.router.url.startsWith('/preferred');
  }

  isPreferredOptionActive(optionId: string): boolean {
    return this.router.url === `/preferred/${optionId}`;
  }
}