<div class="flex m-auto justify-around mt-8 mx-6 text-primary-gray">
    <div class="cursor-pointer hover:text-gray-700 text-[13px]">
        <a href="https://primeaccessinc.com/" target="_blank">
            PA
        </a>
    </div>
    <div class="cursor-pointer hover:text-gray-700 text-[13px]" [routerLink]="['/contact']">
        <a href="https://primeaccessinc.com/contact" target="_blank">
            Contact Us
        </a>
    </div>
    <div class="cursor-pointer hover:text-gray-700 text-[13px]" [routerLink]="['/privacy-policy']">
        <a href="https://primeaccessinc.com/privacy-policy" target="_blank">
            Privacy & Terms
        </a>
    </div>
</div>
