import { Component } from '@angular/core';

@Component({
  selector: 'app-reusable-footer',
  templateUrl: './reusable-footer.component.html',
  styleUrls: ['./reusable-footer.component.scss']
})
export class ReusableFooterComponent {

}
