import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/http/api.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CommancssClassesType, commancssClasses } from 'src/app/util/constant/cssClass.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  userDetails: any;
  isNavbarCollapsed = true;
  visible: boolean = false;
  commanCss: CommancssClassesType = commancssClasses;
  notifications: any = [];
  notificationCount: number = 0;
  isSkeletonVisible: boolean = true;

  constructor(private apiService: ApiService, public sharedService: SharedService, private router: Router) {
    this.sharedService.notify$.subscribe((res: any) => {
      if (res) {
        const index: number = this.notifications.findIndex((i: any) => i.notification_id == res.notification_id);
        if (index > -1) {
          this.notifications[index].is_read = '1';
        }
      }
    })
  }

  ngOnInit() {
    this.userDetails = this.sharedService.getUser();
    setTimeout(() => {
      this.getNotification();
    }, 500);
  }

  /** Get All Notification */
  getNotification() {
    this.apiService.httpGet('notification/fetch').subscribe({
      next: (res: any) => {
        if (res.status == 200 && res.success) {
          this.notifications = res.rows;
          this.notificationCount = res.count;
          this.isSkeletonVisible = false;
        }
      },
      error: (err: any) => {
        this.notifications = [];
        this.isSkeletonVisible = false;
      }
    });
  }

  /** Go to Profile */
  gotoProfile() {
    this.router.navigate(['/profile'])
  }

  /** logout user */
  logout() {
    this.sharedService.logout();
  }

}
